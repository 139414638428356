import React, { Component } from 'react';
import axios from 'axios';
import { SlDrop } from 'react-icons/sl';
import styles from '../css/Weather.module.css';

class Weather extends Component {
  constructor(props) {
    super(props);
    this.state = {
      temp: 0,
      temp_max: 0,
      temp_min: 0,
      humidity: 0,
      desc: '',
      icon: '',
      loading: true,
    };
  }

  componentDidMount() {
    const cityName = 'Yongin';
    const apiKey = process.env.REACT_APP_WEATHER_KEY;
    const url = `https://api.openweathermap.org/data/2.5/weather?q=${cityName}&appid=${apiKey}`;

    axios
      .get(url)
      .then((responseData) => {
        const data = responseData.data;
        this.setState({
          temp: data.main.temp,
          temp_max: data.main.temp_max,
          temp_min: data.main.temp_min,
          humidity: data.main.humidity,
          desc: data.weather[0].description,
          icon: data.weather[0].icon,
          loading: false,
        });
      })
      .catch((error) => console.log(error));
  }

  render() {
    const imgSrc = `https://openweathermap.org/img/wn/${this.state.icon}@2x.png`;

    if (this.state.loading) {
      return <p>Loading</p>;
    } else {
      return (
        <div className={styles.container}>
          <p className={styles.location}>Yongin</p>
          <div className={styles.tempWrapper}>
            <h2 className={styles.tempText}>{(this.state.temp - 273.15).toFixed(0)}°</h2>
            <img className={styles.weatherImg} src={imgSrc} alt="Weather Icon" />
          </div>
          <div className={styles.infoWrapper}>
            <div className={styles.infoRow}>
              <p className={styles.maxMin}>
                {(this.state.temp_max - 273.15).toFixed(0)}° / {(this.state.temp_min - 273.15).toFixed(0)}°
              </p>
              <p className={styles.humidityText}>
                <SlDrop size="20px" style={{ marginRight: '5px', color: '#17a2ff' }} />
                {this.state.humidity}%
              </p>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Weather;