import React, { useState, useRef, useEffect } from 'react';
import style from '../css/MainPage.module.css';
import backgroundImg from '../assets/background2.jpg';
import Weather from '../components/Weather';

const MainPage = () => {
  const [showLinks, setShowLinks] = useState(false);
  const linkContainerRef = useRef(null);

  const handleButtonClick = () => {
    setShowLinks(!showLinks); // 링크 표시 상태 토글
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (linkContainerRef.current && !linkContainerRef.current.contains(event.target)) {
        setShowLinks(false); // 링크 숨기기
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={style.container}>
      <div className={style.leftPanel}>
        <div className={style.signContainer}>
          <button className={style.signButton}>Login</button>
          <button className={style.signButton}>Signup</button>
        </div>
        <button onClick={handleButtonClick} className={style.signButton}>
          Links
        </button>
        {showLinks && (
          <div ref={linkContainerRef} className={style.linkContainer}>
              <a href="https://sssssss51.github.io/portfolio/portfolio.html" target="_blank" rel="noopener noreferrer" className={style.link}>Portfolio</a>
              <a href="https://sssssss51.github.io/" target="_blank" rel="noopener noreferrer" className={style.link}>sssssss51</a>
              <a href="https://durumi.comon.kr/phpmyadmin/" target="_blank" rel="noopener noreferrer" className={style.link}>DB</a>
              <a href="https://khs.comon.kr/" target="_blank" rel="noopener noreferrer" className={style.link}>khs</a>
          </div>
        )}
      </div>
      <div className={style.centerPanel}>
        <img src={backgroundImg} alt="star" className={style.centerImage} />
        <p className={style.p}>Developed by hs101 &copy; 2024.x &nbsp;&nbsp;</p>
      </div>
      <div className={style.rightPanel}>
        <Weather />
      </div>
    </div>
  );
};

export default MainPage;