import { createBrowserRouter } from 'react-router-dom';
import MainPage from '../pages/MainPage';

const router = createBrowserRouter([
  {
    path: '/',
    // errorElement: <ErrorPage />, 에러페이지
    children: [
      {
        path: '',
        element: <MainPage />,
      },
    ],
  },
]);

export default router;